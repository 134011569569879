body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 450px;
  margin: auto;
  position: relative;
  height: calc(100vh)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fa, .far, .fas {
  font-family: "Font Awesome 5 Free" !important;
}

a {
  color: inherit;
  text-decoration: none;
}

.page-loader-height {
  height: calc(100vh - 50px);
}

.closeButton:focus {
  box-shadow: none;
  outline: none;
}

.golden-text {
  color: #967800;
  font-weight: 800;
}

.error-border {
  border: 2px solid #fa6464;
}

.radio-level-margin-left {
  margin-left: 10px;
}

.sekoci-link {
  color: #967800;
  font-weight: 500;
  text-decoration: underline;
  overflow-wrap: break-word;
}

.btn-icon-position {
  justify-content: space-between !important;
  padding: 10px 20px !important;
}

.separator {
  height: 1px;
  margin: 10px 0;
  background-color: #cccccc;
}

.steps-header {
  margin-top: 0 !important;
  padding-top: 58px !important;
}


.collapsible-margin-btm {
  margin-bottom: 20px;
}

.box-shadow {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.01), 0 6px 16px 0 rgba(0,0,0,0.06);
}

.warning-title {
  padding: 14px 21% 0;
  line-height: 1.5;
}

.disabled-btn-with-white {
  background-color: #e6e6e6 !important;
  color: #aaaaaa !important;
}

.text-transform-capital {
  text-transform: capitalize;
}

.bg-white {
  background-color: #ffffff !important;
}

.break-word-newline{
  word-break: break-word;
}

.covid-terms-modal .modal-content {
  padding: 0 !important;
}

.show-snack {
  visibility: visible !important;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.cursor {
  cursor: pointer !important;
}

.caret-rotate-up {
  cursor: pointer;
  color: #aaaaaa;
  transform: rotate(180deg);
  transition: transform 0.5s linear;
}

.caret-rotate-down {
  cursor: pointer;
  color: #aaaaaa;
  transform: rotate(0deg);
  transition: transform 0.5s linear;
}

.animation-hide {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s linear;
}

.animation-show {
  max-height: 84px;
  transition: max-height 0.5s linear;
}

div, section {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

div::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@media only screen and (max-width: 823px) {
  /* For mobile phones: */
  body {
    max-width: 100%;
  }
}

@media only screen 
and (min-device-width: 450px) 
and (max-device-width: 1024px)
and (-webkit-min-device-pixel-ratio: 2)
{
  body {
    max-width: 100%;
  }
}