/* 

  'fas fa-angle-up'
  'fas fa-angle-down'
  'fas fa-angle-left'
  'fas fa-backward'
  'fas fa-angle-right'
  'fas fa-forward'
  'fa fa-times'
  'fas fa-user' 
  'fa fa-check'
  fa-ellipsis-v
  'far fa-user'
  'fas fa-pen'
  fas fa-minus
  fas fa-plus
  fas fa-arrow-right
  fas fa-arrow-left
  far fa-bell
  fas fa-trash-alt
  fab fa-whatsapp
  fas fa-phone phone-rotate
  fas fa-caret-down
  far fa-trash-alt
  fas fa-ellipsis-v
  fas fa-triangle-down
*/
:root {
  --path: process.env.REACT_APP_LOCAL_API_END_POINT;
}


.fab {
  font-family: "Font Awesome 5 Brands"
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  src: url(https://use.fontawesome.com/releases/v5.3.1/webfonts/fa-regular-400.eot);
  src: url(https://use.fontawesome.com/releases/v5.3.1/webfonts/fa-regular-400.eot?#iefix) format("embedded-opentype"),url(https://use.fontawesome.com/releases/v5.3.1/webfonts/fa-regular-400.woff2) format("woff2"),url(https://use.fontawesome.com/releases/v5.3.1/webfonts/fa-regular-400.woff) format("woff"),url(https://use.fontawesome.com/releases/v5.3.1/webfonts/fa-regular-400.ttf) format("truetype"),url(https://use.fontawesome.com/releases/v5.3.1/webfonts/fa-regular-400.svg#fontawesome) format("svg")
}

.far {
  font-weight: 400
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  src: url(https://use.fontawesome.com/releases/v5.3.1/webfonts/fa-solid-900.eot);
  src: url(https://use.fontawesome.com/releases/v5.3.1/webfonts/fa-solid-900.eot?#iefix) format("embedded-opentype"),url(./fonts/fa-solid-900.woff2) format("woff2"),url(./fonts/fa-solid-900.woff) format("woff"),url(./fonts/fa-solid-900.ttf) format("truetype"),url(https://use.fontawesome.com/releases/v5.3.1/webfonts/fa-solid-900.svg#fontawesome) format("svg")
}

.fa,.far,.fas {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
}

.fa, .fas {
  font-weight: 900
}

.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-angle-up:before {
  content: "\f106"
}

.fa-angle-down:before {
  content: "\f107"
}

.fa-angle-left:before {
  content: "\f104"
}

.fa-angle-right:before {
  content: "\f105"
}

.fa-backward:before {
  content: "\f04a"
}


.fa-times:before {
  content: "\f00d"
}

.fa-user:before {
  content: "\f007"
}


.fa-pen:before {
  content: "\f304"
}

.fa-minus:before {
  content: "\f068"
}

.fa-plus:before {
  content: "\f067"
}

.fa-bell:before {
  content: "\f0f3"
}

.fa-trash-alt:before {
  content: "\f2ed"
}

.fa-whatsapp:before {
  content: "\f232"
}

.fa-phone:before {
  content: "\f095"
}

.fa-check:before {
  content: "\f00c";
}

.fa-exclamation-triangle:before {
  content: "\f071"
}

.fa-caret-down:before {
  content:"\f0d7"
}

.fa-trash-alt:before {
  content:"\f2ed"
}

.fa-ellipsis-v:before{
  content: "\f142"
}

.fa-triangle-down::before {
  content: "\f0d7"
}